import i18next from "i18next";
import Polyglot from "i18next-polyglot";

import ru from "../../../locale/ru.json";
import en from "../../../locale/en.json";
import tr from "../../../locale/tr.json";
import de from "../../../locale/de.json";

export const SUPPORTED_LANGUAGES = ["en", "ru", "tr", "de"];

export const i18nInstance = (async () => {
    await i18next
        .use(Polyglot)
        .init({
            lng: "en",
            fallbackLng: "en",
            resources: {
                ru: {
                    translation: ru,
                },
                en: {
                    translation: en,
                },
                tr: {
                    translation: tr,
                },
                de: {
                    translation: de,
                },
            },
        });

    return i18next;
})();
